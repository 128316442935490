import React from 'react';
import { useTranslation } from 'react-i18next';
import { ps } from '@petscreening/embedded-flow-sdk';

import { Row, Title, PageContainer, PageWrapper, Description } from '../common-styles';
import Button from 'components/button';
import Checkbox from 'components/checkbox';

const PET_SCREENING_ENVIRONMENT = 'https://embedded.qa.petscreening.com'; // 'https://embedded.rc.petscreening.com'; // 'https://embedded.uat.petscreening.com',
const PET_SCREENING_PROVIDER_ID_OVERRIDE = 5126;

const PetScreeningEmbed = ({ application, location, saveApplicationPart, onNext, onBack }) => {
  const { t } = useTranslation();
  const handleNext = (petScreeningData) => {
    saveApplicationPart({ petScreeningEmbed: true, petScreeningData }, false).then(() => {
      onNext();
    });
  };

  React.useEffect(() => {
    // // override the messenger so that we can capture the close event!
    // ps.setupMessageListener = function (options = {}) {
    //   window.addEventListener('message', (event) => {
    //     console.log(event);
    //     const allowedOrigins = [
    //       options.overrideUrl,
    //       PET_SCREENING_ENVIRONMENT,
    //       'https://embedded.petscreening.com'
    //     ];
    //     if (!allowedOrigins.includes(event.origin)) return;
    //     if (options.callbacks) {
    //       if (typeof options.callbacks[event.data.type] === 'function') {
    //         options.callbacks[event.data.type](event.data);
    //       }
    //       if (typeof options.callbacks[event.data] === 'function') {
    //         options.callbacks[event.data](event.data);
    //       }
    //     }
    //     if (event.data === 'close') this.closeIframe();
    //   });
    // };
    const spId =
      PET_SCREENING_PROVIDER_ID_OVERRIDE ||
      location?.companyInfo?.petScreeningDetails?.serviceProviderId;
    ps.initEmbeddedFlow('#pet-screening-launcher', {
      overrideUrl: PET_SCREENING_ENVIRONMENT,
      queryParams: { serviceProviderId: spId },
      callbacks: {
        close: (...props) => {
          console.log('Close Event received:', props);
        },
        onEvent: (...props) => {
          console.log('Event received:', props);
        },
        petProfilesCompleted: (props) => {
          console.log('PetProfilesCompleted:', props);
          handleNext(props.data);
        }
      }
    });
  }, []);

  return (
    <PageWrapper>
      <PageContainer>
        <Row className="center text-center">
          <Title>{t('petsMembers.title')}</Title>
        </Row>
        {location?.applicationConfig?.petBlurb && (
          <Row>
            <Description
              dangerouslySetInnerHTML={{
                __html: location.applicationConfig.petBlurb
              }}
            />
          </Row>
        )}
        <Row className="center flex gap-10">
          <Button radius="25px" variant="secondary" width={90} onClick={onBack}>
            {t('buttonsActions.cancel')}
          </Button>
          <Button id="pet-screening-launcher" radius="25px" variant="primary">
            Continue to PetScreening.com
          </Button>
        </Row>
      </PageContainer>
    </PageWrapper>
  );
};

const PetScreening = ({ application, location, saveApplicationPart, onNext, onBack }) => {
  const { t } = useTranslation();
  const [acked, setAcked] = React.useState(!!application.consent?.petScreening);
  const [loading, setLoading] = React.useState(false);

  const handleNext = () => {
    if (!acked || loading) return;
    setLoading(true);
    saveApplicationPart({ consentPetScreening: acked }, false)
      .then(() => {
        onNext();
      })
      .catch(() => setLoading(false));
  };

  return (
    <PageWrapper>
      <PageContainer>
        <Row className="center text-center">
          <Title>{t('petsMembers.title')}</Title>
        </Row>
        {location?.applicationConfig?.petBlurb && (
          <Row>
            <Description
              dangerouslySetInnerHTML={{
                __html: location.applicationConfig.petBlurb
              }}
            />
          </Row>
        )}
        <Row>
          <Checkbox
            checked={acked}
            onChange={() => setAcked(!acked)}
            label={t('petsMembers.petScreeningCheck')}
          />
        </Row>
        <Row className="center flex gap-10">
          <Button radius="25px" variant="secondary" width={90} onClick={onBack}>
            {t('buttonsActions.cancel')}
          </Button>
          <Button
            radius="25px"
            variant="primary"
            onClick={handleNext}
            width={90}
            loading={loading}
            disabled={!acked || loading}>
            {t('buttonsActions.next')}
          </Button>
        </Row>
      </PageContainer>
    </PageWrapper>
  );
};

const vendorComponents = {
  petScreeningEmbed: PetScreeningEmbed,
  petScreening: PetScreening
};

export default ({ petScreeningVendor, ...params }) => {
  let Component = vendorComponents[petScreeningVendor] || vendorComponents.petScreening;

  return <Component {...params} />;
};
